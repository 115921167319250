import * as React from 'react';

import Contact from '../components/contact';
import Layout from '../components/layout';
import Persona from '../components/persona';
import SEO from '../components/seo';
import { personaWrapper } from './about.module.scss';

const schema = `{
    "@context": "https://schema.org",
    "@type": "Person",
    "givenName": "Joe",
    "additionalName": "Raymond",
    "familyName": "Liccini",
    "name": "Joe Liccini",
    "alternateName": "Joseph Liccini",
    "gender": "Male",
    "jobTitle": "Software Engineer",
    "worksFor": {
        "@type": "Organization",
        "name": "Stripe"
    },
    "image": "https://www.webperf.tips/static_Persona_Image.jpg",
    "url": "https://www.webperf.tips/about"
}`;

const AboutPage = () => {
    return (
        <>
            <SEO title={"About Me"}
                 description={"Information about Joe Liccini, the author of Web Performance Tips"}
                 schema={schema}
            />
            <Layout pageTitle="About Me">
                <div className={personaWrapper}>
                    <Persona></Persona>
                </div>
                <section>
                    <p>Hi, I'm Joe Liccini (pronounced Lee-CHEE-Nee) and I work as a Full Stack Software Engineer at Stripe.</p>
                    <p>I am passionate about teaching others how to understand and improve their full-stack web performance.</p>
                    <p>
                        I've worked in the Web Performance space for many years (primarily at Microsoft) and I struggled
                        to find a comprehensive collection of techniques developers can use to optimize their web apps
                        -- so I decided to create one!
                    </p>
                    <p>All content and opinions expressed in this blog are my own and are not reflective of my employer.</p>
                    <Contact></Contact>
                </section>
            </Layout>
        </>
    )
}

export default AboutPage;
